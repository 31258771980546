/* Banner.module.css */
.carousel {
    height: 60vh;
    /* Set the height to 100vh */
}

.carouselItem {
    position: relative;
}

.imageContainer {
    position: relative;
}

.image {
    width: 100%;
    height: 60vh;
    /* Set the image height to 100vh */
    object-fit: cover;
}

.textContainer {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: start;
    color: white;
    font-family: "Montserrat", sans-serif !important;
}

.title {
    font-weight: 700;
}

.description {
    font-weight: 500;
    width: 80%;
    font-size: calc(0.8rem + 0.5vw) !important;
}

.checkButton {
    background: transparent;
    color: black;
    border: 2px solid black;
    border-radius: 25px;
    font-weight: 600;

    transition: 0.5s;
}

.checkButton:hover {
    background: black;
    color: white;
}

@keyframes zoomIn {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.1);
        /* Adjust the scale factor as needed */
    }
}

.zoomInAnimation {
    animation: zoomIn 5s ease-in-out;
    /* Adjust the duration as needed */
    animation-fill-mode: both;
}